import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  List,
  TextField,
  DeleteButton,
  EditButton,
  DateField,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useListContext,
  Count, DatagridConfigurable, useGetList, WrapperField, useTheme,
} from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import SimCardsFilter from './SimCardFilter';
import { Divider, Tabs, Tab } from '@mui/material';
import SimCardsListActions from './SimCardActions';
import SimCardsRowStyle from './SimCardsRowStyle';

const SimCardList = (props: any) => {

  return (
    <List
      {...props}
      filterDefaultValues={{ tag: 'all' }}
      filters={<SimCardsFilter />}
      actions={<SimCardsListActions />}
    >
      <TabbedDatagrid />
    </List>
  );
};

const TabbedDatagrid = () => {
  const [tags, setTags] = useState([]);
  const [theme, ] = useTheme();

  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
      setFilters(
        { ...filterValues, tag: value },
        displayedFilters,
      );
    },
    [displayedFilters, filterValues, setFilters],
  );


  const tagsList: any = useGetList(
    'tags?type=sim_card&',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (tagsList.data) {
      const tagsData = [{ id: 'all', name: 'All' }];
      const tagsToRender = tagsList.data?.map((record: any) => ({ id: record.id, name: record.name }));
      // @ts-ignore
      setTags([...tagsData, ...tagsToRender]);
    }
  }, [tagsList.data]);

  return (
    <Fragment>
      <Tabs
        value={filterValues.tag ?? 'all'}
        onChange={handleChange}
        indicatorColor='primary'
        variant='scrollable'
        sx={{ maxWidth: '80vw' }}
      >
        {tags.map((choice: any) => (
          <Tab
            sx={{ maxWidth: '100%' }}
            key={choice.id}
            label={<span>{choice.name} (<Count filter={{ ...filterValues, tag: choice.id }}
            sx={{ lineHeight: 'inherit' }} />)</span>}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <>
        <DatagridConfigurable
          rowStyle={(record) => {
            return SimCardsRowStyle(record, theme as any);
          }}
        >
          <TextField source='demoId' label='#' />
          <TextField source='phoneNumber' />
          <TextField source='owner' />
          <TextField source='operator' />
          <DateField source='expirationPeriod' />
          <ArrayField source='status'>
            <SingleFieldList linkType={false}>
              <FunctionField
                label='Address'
                render={(record: any) => {
                  const color = record.color || 'default';

                  return (
                    <ChipField
                      color={color}
                      source='name'
                      size='small'
                    />
                  );
                }}
              />

            </SingleFieldList>
          </ArrayField>
          <FunctionField
            label='Notes'
            render={(record: any) => {
              const truncatedText = record.notes ? record.notes.substring(0, 7) : '';
              const fullText = record.notes;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          <WrapperField label='Edit'>
            <EditButton />
          </WrapperField>
          <WrapperField label='Delete'>
            <DeleteButton label='Delete' mutationMode='pessimistic' />
          </WrapperField>
        </DatagridConfigurable>
      </>
    </Fragment>
  );
};

export default SimCardList;
