import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  List,
  TextField,
  DeleteButton,
  EditButton,
  EmailField,
  DateField,
  downloadCSV,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useListContext,
  Count, DatagridConfigurable, useGetList, WrapperField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import Tooltip from '@mui/material/Tooltip';
import AccountsFilter from './AccountsFilter';
import { Divider, Tabs, Tab } from '@mui/material';
import AccountsListActions from './AccountsActions';

function formatISODate(isoDate: string) {
  const date = new Date(isoDate);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${day}.${month}.${year}`;
}

const AccountList = (props: any) => {
  const exporter = (accounts: any) => {
    const accountsForExport = accounts.map((acc: any) => {
      const {
        updatedAt,
        createdAt,
        _id,
        __v,
        phoneNumber,
        registrationDate,
        fullName,
        accountName,
        demoId,
        tags,
        ...accForExport
      } = acc;
      accForExport.id = acc.demoId;
      accForExport.account_name = acc.accountName;
      accForExport.full_name = acc.fullName;
      accForExport.email = acc.email;
      accForExport.password = acc.password;
      accForExport.phone_number = acc.phoneNumber;
      accForExport.registration_date = formatISODate(acc.registrationDate);
      accForExport.comment = acc.comment;

      return accForExport;
    });
    jsonExport(accountsForExport, {
      headers: ['id', 'account_name', 'full_name', 'email', 'password', 'phone_number', 'registration_date', 'comment'],
    }, (err, csv) => {
      downloadCSV(csv, 'accounts');
    });
  };

  return (
    <List
      {...props}
      filterDefaultValues={{ tag: 'all' }}
      exporter={exporter}
      filters={<AccountsFilter />}
      actions={<AccountsListActions />}
    >
      <TabbedDatagrid />
    </List>
  );
};

const TabbedDatagrid = () => {
  const [tags, setTags] = useState([]);

  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
      setFilters(
        { ...filterValues, tag: value },
        displayedFilters,
      );
    },
    [displayedFilters, filterValues, setFilters],
  );


  const tagsList: any = useGetList(
    'tags?type=account&',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (tagsList.data) {
      const tagsData = [{ id: 'all', name: 'All' }];
      const tagsToRender = tagsList.data?.map((record: any) => ({ id: record.id, name: record.name }));
      // @ts-ignore
      setTags([...tagsData, ...tagsToRender]);
    }
  }, [tagsList.data]);

  return (
    <Fragment>
        <Tabs
          value={filterValues.tag ?? 'all'}
          onChange={handleChange}
          indicatorColor='primary'
          variant='scrollable'
          sx={{ maxWidth: '80vw' }}
        >
          {tags.map((choice: any) => (
            <Tab
              sx={{ maxWidth: '100%' }}
              key={choice.id}
              label={<span>{choice.name} (<Count filter={{ ...filterValues, tag: choice.id }} sx={{ lineHeight: 'inherit' }} />)</span>}
              value={choice.id}
            />
          ))}
        </Tabs>
      <Divider />
      <>
        <DatagridConfigurable>
          <TextField source='demoId' label='#' />
          <TextField source='accountName' />
          <DateField source='registrationDate' />
          <EmailField source='email' />
          <ArrayField source='tags'>
            <SingleFieldList linkType={false}>
              <FunctionField
                label='Address'
                render={(record: any) => {
                  const color = record.color || 'default';

                  return (
                    <ChipField
                      color={color}
                      source='name'
                      size='small'
                    />
                  );
                }}
              />

            </SingleFieldList>
          </ArrayField>
          <TextField source='fullName' />
          <TextField source='password' />
          <TextField source='phoneNumber' />
          <FunctionField
            label='Address'
            render={(record: any) => {
              const truncatedText = record.address ? record.address.substring(0, 7) : '';
              const fullText = record.address;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          <FunctionField
            label='Comment'
            render={(record: any) => {
              const truncatedText = record.comment ? record.comment.substring(0, 7) : '';
              const fullText = record.comment;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          <WrapperField label="Edit">
            <EditButton />
          </WrapperField>
          <WrapperField label="Delete">
            <DeleteButton label='Delete' mutationMode='pessimistic' />
          </WrapperField>
        </DatagridConfigurable>
      </>
    </Fragment>
  );
};

export default AccountList;
