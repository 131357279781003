import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  List,
  TextField,
  DeleteButton,
  EditButton,
  DateField,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useListContext,
  Count, DatagridConfigurable, useGetList, WrapperField,
} from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import { Divider, Tabs, Tab } from '@mui/material';
import TaskFilter from './TaskFilter';
import TaskListActions from './TaskActions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const HighPriority = () => <div style={{ color: '#8A2BE2', fontWeight: 'bold' }}>HIGH</div>;
const MediumPriority = () => <div style={{ color: '#4682B4', fontWeight: 'bold' }}>MEDIUM</div>;
const LowPriority = () => <div style={{ color: '#ADD8E6', fontWeight: 'bold' }}>LOW</div>;

const TaskList = (props: any) => {

  return (
    <List
      {...props}
      filterDefaultValues={{ tag: 'all' }}
      filters={<TaskFilter />}
      actions={<TaskListActions />}
    >
      <TabbedDatagrid />
    </List>
  );
};

const TabbedDatagrid = () => {
  const [tags, setTags] = useState([]);

  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
      setFilters(
        { ...filterValues, tag: value },
        displayedFilters,
      );
    },
    [displayedFilters, filterValues, setFilters],
  );


  const tagsList: any = useGetList(
    'tags?type=tasks&',
    {
      sort: { field: 'name', order: 'DESC' },
    },
  );

  useEffect(() => {
    if (tagsList.data) {
      const tagsData = [{ id: 'all', name: 'All' }, { id: 'pending', name: 'pending' }, { id: 'in progress', name: 'in progress' }, { id: 'completed', name: 'completed' }, { id: 'canceled', name: 'canceled' }];
      const tagsToRender = tagsList.data?.map((record: any) => ({ id: record.id, name: record.name }));
      // @ts-ignore
      setTags([...tagsData, ...tagsToRender]);
    }
  }, [tagsList.data]);

  return (
    <Fragment>
      <Tabs
        value={filterValues.tag ?? 'all'}
        onChange={handleChange}
        indicatorColor='primary'
        variant='scrollable'
        sx={{ maxWidth: '80vw' }}
      >
        {tags.map((choice: any) => (
          <Tab
            sx={{ maxWidth: '100%' }}
            key={choice.id}
            label={
              <span>{choice.name} (<Count filter={{ ...filterValues, tag: choice.id }} sx={{ lineHeight: 'inherit' }} />)</span>
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <>
        <DatagridConfigurable>
          <TextField source='demoId' label='#' />
          <TextField source='name' />
          <FunctionField
            label='Status'
            render={(record: any) => {
              const { status } = record;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{status}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {status === 'pending' && (<AccessTimeIcon color='info' />)}
                    {status === 'in progress' && (<AutorenewIcon color='warning' />)}
                    {status === 'completed' && (<CheckCircleIcon color='success' />)}
                    {status === 'canceled' && (<CancelIcon color='error' />)}
                  </div>
                </Tooltip>
              );
            }}
          />
          <TextField source='assignee' />
          <DateField source='dueDate' showTime />
          <FunctionField
            label='Priority'
            render={(record: any) => {
              const { priority } = record;

              if (priority === 'high') {
                return (
                  <HighPriority/>
                )
              } else if (priority === 'medium') {
                return (
                  <MediumPriority />
                )
              } else if (priority === 'low') {
                return (
                  <LowPriority />
                )
              }
            }}
          />
          <ArrayField source='tags'>
            <SingleFieldList linkType={false}>
              <FunctionField
                label='Address'
                render={(record: any) => {
                  const color = record.color || 'default';

                  return (
                    <ChipField
                      color={color}
                      source='name'
                      size='small'
                    />
                  );
                }}
              />
            </SingleFieldList>
          </ArrayField>


          <FunctionField
            label='Description'
            render={(record: any) => {
              const truncatedText = record.description ? record.description.substring(0, 7) : '';
              const fullText = record.description;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          <FunctionField
            label='Comment'
            render={(record: any) => {
              const truncatedText = record.comment ? record.comment.substring(0, 7) : '';
              const fullText = record.comment;

              return (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>{fullText}</span>
                  }
                >
                  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {truncatedText} {truncatedText < fullText && '...'}
                  </div>
                </Tooltip>
              );
            }}
          />
          <WrapperField label='Edit'>
            <EditButton />
          </WrapperField>
          <WrapperField label='Delete'>
            <DeleteButton label='Delete' mutationMode='pessimistic' />
          </WrapperField>
        </DatagridConfigurable>
      </>
    </Fragment>
  );
};

export default TaskList;
